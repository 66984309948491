<template>
  <div>
    <h4 class="pb-4">Latest Transactions</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Payment Type</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in latestTransactions" :key="payment.id">
            <td>{{ payment.payment_type }}</td>
            <td>{{ payment.amount }}</td>
            <td>{{ payment.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <router-link to="/payments">
      <button type="button" class="btn btn-primary float-right view-all">
        View all
      </button>
    </router-link>
  </div>
</template>

<script>
import { freeSet } from "@coreui/icons";
import { mapGetters } from "vuex";

export default {
  freeSet,
  computed: {
    ...mapGetters("Payment", ["latestTransactions"]),
  },
  mounted() {
    this.$store.dispatch("Payment/getLatestTransactions");
  },
};
</script>

<style lang="scss" scoped>
@media (width: 1366px) {
  div {
    button {
      margin-top: 20px;
    }
  }
}
</style>

